class Footer extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.innerHTML = `
        <footer class="fs-footer">
			<div class="social">
				<!-- Facebook -->
				<a href="https://www.facebook.com/flexsolver/" target="_blank">
					<svg id="Component_46_1" data-name="Component 46 – 1" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
						<g class="inner-circle" data-name="Ellipse 113" fill="none" stroke="#fff" stroke-width="1">
							<circle cx="13" cy="13" r="13" stroke="none" />
							<circle cx="13" cy="13" r="12.5" fill="none" />
						</g>
						<g class="social-logo" transform="translate(-13.077 6)">
							<path id="Path_1597" data-name="Path 1597"
								d="M29.776,0,27.857,0a3.37,3.37,0,0,0-3.549,3.642V5.321H22.379a.3.3,0,0,0-.3.3V8.055a.3.3,0,0,0,.3.3h1.929V14.5a.3.3,0,0,0,.3.3h2.517a.3.3,0,0,0,.3-.3V8.357h2.256a.3.3,0,0,0,.3-.3V5.622a.3.3,0,0,0-.3-.3H27.429V3.9c0-.684.163-1.031,1.054-1.031h1.293a.3.3,0,0,0,.3-.3V.3A.3.3,0,0,0,29.776,0Z"
								fill="#fff" />
						</g>
					</svg>
				</a>
				<!-- LinkedIn -->
				<a href="https://www.linkedin.com/company/flex-solver-pte-ltd" target="_blank">
					<svg id="Component_45_1" data-name="Component 45 – 1" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
						<g class="inner-circle" data-name="Ellipse 113" fill="none" stroke="#fff" stroke-width="1">
							<circle cx="13" cy="13" r="13" stroke="none" />
							<circle cx="13" cy="13" r="12.5" fill="none" />
						</g>
						<g class="social-logo" transform="translate(6.441 6.089)">
							<path id="Path_1598" data-name="Path 1598"
								d="M1.778-.668A1.64,1.64,0,0,0,0,.966,1.627,1.627,0,0,0,1.737,2.6h.02A1.639,1.639,0,1,0,1.778-.668Zm0,0"
								transform="translate(0 0)" fill="#fff" />
							<path id="Path_1599" data-name="Path 1599" d="M8.109,198.312h3.143v9.455H8.109Zm0,0"
								transform="translate(-7.924 -194.422)" fill="#fff" />
							<path id="Path_1600" data-name="Path 1600"
								d="M227.19,188.625a3.937,3.937,0,0,0-2.832,1.593v-1.371h-3.143V198.3h3.143v-5.28a2.154,2.154,0,0,1,.1-.767,1.72,1.72,0,0,1,1.612-1.149c1.137,0,1.592.867,1.592,2.138V198.3h3.142v-5.421C230.808,189.976,229.257,188.625,227.19,188.625Zm0,0"
								transform="translate(-216.147 -184.957)" fill="#fff" />
						</g>
					</svg>
				</a>
			</div>
			<div class="links">
				<div class="container px-5">
					<div class="row footer-content no-gutters">
						<div class="col-lg-4 col-md-12 mb-3 mb-lg-0">
							<p class="font-weight-500">Quicklinks</p>
							<div class="row justify-content-between">
								<div class="col-12">
									<a href="/" class="d-block">Home</a>
									<a href="retail-fnb-hub.html" class="d-block">Flex Retail/F&B Hub</a>
									<a href="people-hub.html" class="d-block">Flex People Hub</a>
									<a href="experience-hub.html" class="d-block">Flex Experience Hub</a>
                                    <a href="our-story.html" class="d-block">Our Story</a>
									<a href="careers.html" class="d-block">Careers</a>
								</div>
							</div>
						</div>
						<div class="mx-lg-auto col-lg-3 col-md-12 mb-3 mb-lg-0">
							<p class="font-weight-500">Get In Touch</p>
							<a href="mailto:info@flex-solver.com.sg" class="d-block">info@flex-solver.com.sg</a>
							<a href="tel:63846598" class="d-block">(+65) 6384 6598</a>
						</div>
						<div class="ml-lg-auto col-lg-3 col-md-12">
							<p class="font-weight-500">Find Us</p>
							<p>114 Lavender St., #06-61, Singapore&nbsp;338729</p>
						</div>
					</div>
				</div>
			</div>
			<div class="copyright py-3">
				<div class="container px-5">
					<small class="d-block text-white text-center text-lg-left">&copy; 2025 Flex-Solver <sup>&reg;</sup> All Rights Reserved.</small>
				</div>
			</div>
		</footer>
        `
    }
}

customElements.define('footer-content', Footer);